<template>
  <div class="header_css w-100 mb-4">
    
    <span v-if="!isLoggedIn">{{ redirect() }}</span>
    <b-col class="text-right pt-lg-3 pt-3 pr-lg-4 mr-5" id="eighth">
      <!-- <span class="text-white btn-css menuhoverd mr-2" @click="Logout()">
        Logout</span
      > -->
    </b-col>
    <b-col class="text-center pt-lg-3 pr-lg-4 mr-5" id="eighth"> </b-col>
    <b-navbar toggleable="lg" type="dark" class="pt-0">
      <b-navbar-brand href="/" class="pl-lg-5 text-size-logo pl-2"
        ><img src="/img/1st-03.png" height="80"
      /></b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
  
      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto text-dark pr-5">
          <b-navbar-nav class="nav-txt1 text-size" id="eighth" pills>
            <b-nav-item class="menuhoverd pb-0" to="/"
              ><span>Home</span></b-nav-item
            >
             <b-nav-item-dropdown
              id="my-nav-dropdown"
             
            >
              <template slot="button-content">
               My Plans
              </template>
             <p>  <!-- {{ username }} --></p>
             <span v-if="this.plansdetails.length !=0">
              <CDropdownItem  v-for="(list, index) in plansdetails.filter((demo)=>{return demo.status==1})"
                  :key="index" class="text-primary" :href="'/plandata/'+list.plans_id" >{{list.plan_name}} Plan
              </CDropdownItem>
              </span>
              <!-- <span  v-if="this.plansdetails.length !=0">
                 <CDropdownItem class="text-primary" >No Active Plan
              </CDropdownItem>
              </span> -->
              <!-- <CDropdownDivider /> -->
            </b-nav-item-dropdown>
            <b-nav-item class="menuhoverd" to="/history"
              ><span>History</span></b-nav-item
            >
            <b-nav-item class="menuhoverd" to="/dashboard"
              ><span>My Dashboard</span></b-nav-item >
             <b-nav-item v-if="isLoggedIn" class="menuhoverd" to="/cart"
              ><span>
              <span v-if="this.useridCountCart.cartcount >0" class="cart-counter" style="position: absolute; margin-left: 0.5rem; margin-top: -1rem;font-size:15px">{{this.useridCountCart.cartcount}}</span>
              <b-icon icon="cart4" aria-hidden="true"></b-icon> Cart</span
              ></b-nav-item >
             <b-nav-item-dropdown
              id="my-nav-dropdown"
              :text="username"
              toggle-class="nav-link-custom"
              right
            >
              <template slot="button-content">
                <b-icon
                  icon="person"
                  class="display-inline user-icon-default"
                  aria-hidden="true"
                ></b-icon>
              
              </template>
             <p>  <!-- {{ username }} --></p>
              <CDropdownItem class="text-primary" to="/profile">
                <CIcon name="cil-user" /> Profile
              </CDropdownItem>

              <CDropdownDivider />
              <CDropdownItem class="text-primary" @click="Logout()">
                <CIcon name="cil-lock-locked" /> Logout
              </CDropdownItem>
            </b-nav-item-dropdown>
            
           
          </b-navbar-nav>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal @ok="ok()"  @cancel="cancel()" 
        label=""
        data-backdrop="false"
        class="modal fade"
        ref="my-modal"
        id="errorVerification-modal"
        hide-header
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModalLabel">
      <div>
        <h5>You are already logged in on a different device, would you like to login again?</h5>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    
    return {
      username: null,
      useridCountCart:{
        userid:null,
        cartcount:null,
        token:null,
      },
      plansdetails: [],
      userdetect: {
        user_id12: null,
        pagelink:null,
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
  },
  created() {
    if (this.isLoggedIn) {
      // this.getUser();
      this.getUserplan();
      this.CartCount();
      this.storelog();

    }
  },
  methods: {
    ...mapActions(["getUserData", "sendLogoutRequest","CartCountRequest","getUserplanlist","StorelogData"]),
    // getUser() {
    //   // this.getUserData().then((res) => {
    //   //   console.log(res);
    //   //   this.username = res.data.user.name;
    //   // });
    // },
    Logout() {
      this.sendLogoutRequest().then((res) => {
        setTimeout(() => {
          window.location.href = "/";
          localStorage.setItem("popup_premiumcontact", 0);
        }, 3000);
      });
    },
    redirect() {
      window.location.href="/";
    },
    storelog(){
      this.userdetect.pagelink=window.location.pathname;

      console.log(userdetect);
      this.userdetect.user_id12= localStorage.getItem( "user");
      this.userdetect.token = localStorage.getItem("token");
       this.StorelogData(this.userdetect).then((res) => {
      });
    },
     getUserplan() {
       this.userdetect.user_id12 = localStorage.getItem("user");
       this.userdetect.token = localStorage.getItem("token");
      
      this.getUserplanlist(this.userdetect)
       .then((response) => {
          this.plansdetails = response.data.data;
        })
        .catch((error) => {
          this.showVerificationModal();
          console.log(error);
          // localStorage.removeItem("token");
        });
      // .then((res) => {
        
      //   this.plansdetails = res.data.data;
      // });
    },
    showVerificationModal() {
      this.$refs["my-modal"].show();
    },
    ok(){
      this.Logout();
    },
    cancel(){
      this.Logout();
    },
    CartCount(){
      this.useridCountCart.userid= localStorage.getItem( "user");
      this.useridCountCart.token = localStorage.getItem("token");
      this.CartCountRequest(this.useridCountCart).then((response) => {
        this.useridCountCart.cartcount=response.data.data[0];
      })
    },
  },
};
</script>
<style >
.navbar.navbar-dark.navbar-nav.nav-link {
  color: unset !important;
  text-decoration: none !important;
}
.nav-link {
  color: white !important;
}
.text-size {
  font-size: 1.3rem;
}
.text-size-logo {
  font-size: 2rem;
}
.user-css {
  text-decoration: none;
  color: #fa2b56;
  font-size: 1.5rem;
}
.loginreg {
  background-color: #fa2b56;
  border-radius: 10px;
}
.bg-pink {
  background-color: #fa2b56;
}
@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");
.demo {
  display: none;
}
.button:hover span {
  padding-right: 25px;
}
.details-button {
  padding-right: 0px !important;
}
.details-button span {
  max-width: 0;
  display: inline-flex;
  white-space: nowrap;
  transition: max-width 0.5s, padding-right 0.45s;
  padding-left: 5px;
}
.details-button:hover span,
.details-button:focus span {
  max-width: 100px;
  padding-right: 10px !important;
}
/* Demlo css  */
.menuhoverd {
  color: white;
  outline: none;
  cursor: pointer;
}
.menuhoverd {
  transition: all 0.5s ease-in-out;
  padding-right: 1rem;
}
.menuhoverd:hover {
  color: #fa2b56;
}.menuhoverd:hover .cart-counter {
  color: #fff;
}

.menuhoverd:hover span {
  transition: all 0.2s ease-in-out;
  border-bottom: white 1px solid;
  color: #fa2b56;
}
.menuhoverd:hover a {
  transition: all 0.5s ease-in-out;
  color: #fa2b56;
  border: none;
}
.menuhoverd .nav-link {
  padding-bottom: 0px !important;
}
a {
  color: white;
  text-decoration-line: none;
}
.header_css {
  box-shadow: 0 0 10px rgb(0 0 0 / 18%);
  position: fixed;
  z-index: 2;
  background: #2748c6;
  animation: smoothScroll 1s forwards;
}
.btn-css {
  padding: 0.3rem 1rem;
  border: 1px solid #fff;
  border-radius: 1rem 1rem;
}

.display-inline {
  display: inline;
}
.user-icon-default {
  padding: 5px;
  background: #fa2b56;
  border-radius: 3rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.user-name:hover {
  /* color: #FA2B56; */
  transition: all 0.2s ease-in-out;
  border-bottom: white 1px solid;
  color: #fa2b56;
}
router-link {
  text-decoration-line: none;
}
.active {
  background: none !important;
}
.cart-counter{
  
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    border-radius:100%;
}
.dropplans{
      position: absolute;
    color: black;
    background: white;
    padding: 1rem 2rem;
    border-radius: 8%;
    display: none;
    list-style: none;
    font-size: 1.2rem;
}
.myplanhover :focus .dropplans{
  display: block;
  color: black;
}
</style>