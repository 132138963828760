<template>
  <div>
    <!-- <TheSidebar/> -->
    <CWrapper>
      <TheHeader />

      <section style="margin-top: 10rem">
        <div class="row">
          <div class="col-sm-12">
            <h5 class="ml-lg-5 text-dark font-style">Data List</h5>
          </div>
        </div>
        <div
          class="row m-lg-5 text-center shadow rounded p-lg-3"
          style="overflow-x: auto"
        >
          <div v-if="message != null" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
          <CRow>
            <CCol v-if="this.$route.params.id == 14" sm="12">
              <CRow class="ml-2">
                <CCol sm="3">
                  <label>State </label>

                  <b-form-select
                    v-model="searchdata.country"
                    :custom-label="customLabel"
                    :options="Countryoptions123"
                  ></b-form-select>
                </CCol>
                <CCol sm="3">
                  <CInput
                    type="text"
                    v-model="searchdata.search_filter"
                    placeholder="Search"
                    label="Search "
                  />
                </CCol>
                <CCol sm="3"
                  ><br />
                  <button
                    class="batn mt-lg-2 float-lg-left p-2"
                    @click="search()"  >
                    <b-icon icon="search" aria-hidden="true"></b-icon> Search
                  </button>
                </CCol>
                <CCol sm="3"
                  ><br />
                  <button class="batn mt-lg-2 p-2" @click="restfunction()">
                    <b-icon icon="search" aria-hidden="true"></b-icon> Reset
                    Filter
                  </button>
                </CCol>
              </CRow>
            </CCol>
          
          </CRow>
          <CRow>
            <table
              class="table w-100 ml-2"
              onmousedown="return false"
              onselectstart="return false"
            >
              <thead>
                <tr>
                  <th scope="col">#</th>
                   <th>State</th>
                  <th scope="col">Company Name</th>
                  <th scope="col">View</th>
                  <!-- <th scope="col">Website</th>
                  <th scope="col">Contact Email</th>
                  <th scope="col">Contact Number</th> -->
                </tr>
              </thead>
              <tbody>
                <tr
                  @contextmenu.prevent
                  v-for="(list, index) in plandatafetch"
                  :key="index"
                >
                  <td>{{ index + count + 1}}</td>
                  <td >{{ list.state_name }}</td>
                  <td >{{ list.company }}</td>
                    <td><button class="bg-primary text-white batn" @click="valueFetchModel(list.id)" v-b-modal.modal-detail >View Data</button></td>

                  <!-- <td >{{ list.country_name }}</td> -->
                 
                </tr>
              </tbody>
                <b-modal size="xl" id="modal-detail" title="More Details" hide-footer>
                  <table class="table w-100 ml-2">
              <thead>
                <tr>
                  <th scope="col">Company Name</th>
                    <th scope="col">Address</th>
                  <th scope="col">Contact Email</th>
                  <th scope="col">Contact Number</th>
          
                </tr>
              </thead>
              <tbody>
                <tr >
                  <td>{{this.dataModelPlanPerticular.company}}</td>
                    <td>{{this.dataModelPlanPerticular.address}}</td>
                  <td>{{this.dataModelPlanPerticular.email}}</td>
                  <td>{{this.dataModelPlanPerticular.contact_number}}</td>
                
                </tr>
              </tbody>
                   </table>
              </b-modal>
              <span style="text-align: center" v-if="plandatafetch == ''"
                >No Data Found</span
              >
            </table>
            <div class="table w-100 ml-2">
              <Pagination
                v-if="this.$route.params.id != 1"
                :totalPages="totalpage"
                :perPage="20"
                :currentPage="currentPage"
                @pagechanged="onPageChange"/>
                <b-button v-if="this.$route.params.id == 1" class="bg-primary text-white" v-b-modal.modal-1>More Data</b-button>
            </div>
          </CRow>
        </div>
      </section>
     
    </CWrapper>
  </div>
</template>

<script>
import TheSidebar from "../../containers/TheSidebar";
import TheHeader from "../../containers/TheHeader";
import TheFooter from "../../containers/TheFooter";
import Pagination from "../../components/Pagination";
import Plans from "../../components/plans";
import axios from "axios";

import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      dataModelPlanPerticular:[],
            userlistdisplay: [],
  cartvalue:null,
      message: null,
      Countryoptions123: null,
      showDismissibleAlert: false,
      totalpage: 1,
      plandatafetch: [],
      perPage: 20,
      currentPage: 1,
      count:0,
      userdetect: {
        user_id12: localStorage.getItem("user"),
        param_plan: null,
        data_id: null,
        page: 1,
        counData: null,
        searchData123: null,
      },
      searchdata: {
        search_filter: null,
        country: null,
      },
      currentPage: 1,
      totalPageCount: 0,
      searchResult: null,
    };
  },
  name: "Dashboard",
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    Pagination,
    Plans
  },
  computed: {
    ...mapGetters(["user"]),
    isLoggedIn: function () {
      return localStorage.getItem("token");
    },
    rows() {
      return this.plandatafetch.length;
    },
  },

  created() {
    if (this.isLoggedIn) {
       console.log('link'+ process.env.BASE_URL);
      this.DomgetUserplandatalist(1);
      this.fetchstate();
      this.fetchplanDynamic();
    }
  },

  methods: {
    ...mapActions([
      "DomgetUserplanlistperticular",
      "StateDroptDown",
      "CountryWiseData",
      "CountryWiseDatasearch",
      "FlagUpdateData",
      "addToCart12","DomesticFetchModelData"
    ]),
   
    restfunction() {
      location.reload();
    },
    goToCart(pid) {
      let user = localStorage.getItem("user");
      this.addToCart12({ id: pid, user_id: user }).then((response) => {
        window.location.href = "/cart";
      });
    },
     fetchplanDynamic() {
      axios.get(process.env.VUE_APP_API_URL + "PlanList").then((resp) => {
        this.userlistdisplay = resp.data.planlist;
      });
    },
    fetchstate() {
      this.StateDroptDown().then((resp) => {
        let arr = [];
        resp.data.data.forEach((res) => {
          arr.push({ text: res.state_name, value: res.id });
        });
        this.Countryoptions123 = arr;
      });
    },
    customLabel(www) {
      return `${www.text}`;
    },
    DomgetUserplandatalist(page) {
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.userdetect.param_plan = this.$route.params.id;
      this.userdetect.page = page;
      this.userdetect.counData = this.searchdata.country;
      this.userdetect.searchData123 = this.searchdata.search_filter;
      this.DomgetUserplanlistperticular(this.userdetect).then((res) => {
      this.totalpage = Math.ceil(res.data.data.total / 20);
      this.plandatafetch = [].concat.apply(
        [],
        res.data.data.countrydatamult.data
      );
    
      });
      this.count=0;
    },
    search() {
      this.userdetect.user_id12 = localStorage.getItem("user");
      this.userdetect.token = localStorage.getItem("token");

      this.userdetect.param_plan = this.$route.params.id;
      this.userdetect.page = 1;
      this.userdetect.counData = this.searchdata.country;
      this.userdetect.searchData123 = this.searchdata.search_filter;
      this.DomgetUserplanlistperticular(this.userdetect).then((res) => {
        this.totalpage = res.data.data.total;
        this.plandatafetch = [].concat.apply(
          [],
          res.data.data.countrydatamult.data
        );
      });
    },
    onPageChange(page) {
      this.DomgetUserplandatalist(page);
      this.currentPage = page;
      this.count = 20*(page-1);
    },
    valueFetchModel(id){
      this.DomesticFetchModelData({planid:id}).then((res)=> {
        this.dataModelPlanPerticular=res.data.data.countrydatamult;
      })
    }
  },
};
</script>
<style scoped>
.batn {
  background: #1e38a7;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.batn:hover {
  background: #002ef8;
  color: white;
  border: none;
  border-radius: 28px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.background_effect:nth-child(odd) {
  background: #1e38a7 !important;
  color: #f8fbfd;
}
.background_effect:nth-child(odd) span:hover a {
  color: white;
}
.background_effect:nth-child(even) span:hover a {
  color: #fa2b56;
}
.bg-pink {
  background-color: #fa2b56;
}
.pink {
  color: #fa2b56;
}
.btn-three34 span a {
  text-decoration: none;
}
.btn-three34 span {
  color: #fff;
  font-weight: 700;
  transition: all 0.5s;
  position: relative;
  padding: 0.5rem 1rem;
  background-color: #fa2b56;
  border-radius: 25px;
}
.btn-three34:hover span {
  background: none;
  border: 1px solid #fa2b56;
  color: #fa2851 !important;
}
.blurry-text {
   color: transparent;
   text-shadow: 0 0 5px rgba(0,0,0,0.5);
}
</style>